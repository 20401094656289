import React, { useEffect } from 'react';
import { Modal, notification } from 'antd';
import { useRawDataContext } from '../../../../_shared/context/raw-data-context';
import { useRemoveRaw } from '../../../../network/services/rawdata.service';
import { useSession } from '../../../../_shared/context';
import { WarningOutlined } from '@ant-design/icons';

interface ConfirmRemoveRowsDialogProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

export const ConfirmRemoveRowsDialog: React.FC<ConfirmRemoveRowsDialogProps> = ({ open, onConfirm, onCancel }) => {

  const { currentProject } = useSession()
  const { namesToDeleteFromDataAggregated, setRawRemoved } = useRawDataContext()
  const removeRowsMutation = useRemoveRaw()

  const handleConfirm = () => {


    removeRowsMutation.mutate({
      projectId: currentProject?.id ?? '',
      names: namesToDeleteFromDataAggregated,
      organizationId: currentProject?.organizationId ?? ''
    }, {
      onSuccess: async (res: any) => {
        setRawRemoved()
        if (res?.data?.status) {
          notification.success({
            message: `Succesfully removed the next variables from the project: ${namesToDeleteFromDataAggregated}`,
          })
        } else {
          notification.error({
            message: 'Could not removed selected variables, please try again later',
          })
        }
      },
      onSettled: async () => {
        notification.info({
          message: `Removing selected variables`
        })
      }
    })

    onCancel();
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal
      title="Confirm Removal"
      visible={open}
      onOk={handleConfirm}
      onCancel={handleCancel}
      okText="Confirm"
      cancelText="Cancel"
      destroyOnClose
    >
      <p>
        Are you sure you want to remove the selected {namesToDeleteFromDataAggregated.length} ({namesToDeleteFromDataAggregated.join(',')})
        {namesToDeleteFromDataAggregated.length === 1 ? ' row' : ' rows'} from the project creation?
      </p>
      <p>
        <WarningOutlined /> <span style={{
          fontWeight: 'bold'
        }}>
          Please note that ingredients and outcomes that are removed will not be available any longer including for goals definition,
          constraints and for goal management.
        </span>
      </p>
    </Modal>
  );
};