import { useMutation, useQuery } from 'react-query';
import { ConstraintRepository } from '../repositories/constraint.repository';
import { IUpsertManyConstraintsData } from '../interfaces/constraints.interface';

export const useCreateConstraint = () => {
  const mutation = useMutation((data: any) => {
    return ConstraintRepository.postCreateConstraint(data);
  });
  return mutation;
};

export const useGetConstraint = (data: {
  organizationId: string;
  projectId: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getConstraint`, data.organizationId, data.projectId],
    ConstraintRepository.getConstraint,
    {
      enabled: true,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const useDeleteConstraint = () => {
  const mutation = useMutation((data: any) => {
    return ConstraintRepository.deleteConstraint(data);
  });
  return mutation;
};

export const usePutUpsertManyConstraints = () => {
  const mutation = useMutation((data: IUpsertManyConstraintsData) => {
    return ConstraintRepository.putUpsertManyConstraint(data)
  })
  return mutation
}

export const usePostMinCostForConstraints = () => {
  const mutation = useMutation((data: IUpsertManyConstraintsData) => {
    return ConstraintRepository.postMinCostForConstraints(data)
  })
  return mutation
}
