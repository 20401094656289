// Generated automatically by nearley, version 2.20.1
// http://github.com/Hardmath123/nearley
(function () {
function id(x) { return x[0]; }
var grammar = {
    Lexer: undefined,
    ParserRules: [
    {"name": "_$ebnf$1", "symbols": []},
    {"name": "_$ebnf$1", "symbols": ["_$ebnf$1", "wschar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "_", "symbols": ["_$ebnf$1"], "postprocess": function(d) {return null;}},
    {"name": "__$ebnf$1", "symbols": ["wschar"]},
    {"name": "__$ebnf$1", "symbols": ["__$ebnf$1", "wschar"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "__", "symbols": ["__$ebnf$1"], "postprocess": function(d) {return null;}},
    {"name": "wschar", "symbols": [/[ \t\n\v\f]/], "postprocess": id},
    {"name": "Main", "symbols": ["Expression"]},
    {"name": "Expression", "symbols": ["Condition"]},
    {"name": "Expression", "symbols": ["Range"]},
    {"name": "Expression", "symbols": ["Assignment"]},
    {"name": "Expression", "symbols": ["IfExpression"]},
    {"name": "Identifier$ebnf$1", "symbols": [/[a-zA-Z]/]},
    {"name": "Identifier$ebnf$1", "symbols": ["Identifier$ebnf$1", /[a-zA-Z]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "Identifier", "symbols": ["Identifier$ebnf$1"], "postprocess": (d) => d.join("").toString().replace(/,/g, "")},
    {"name": "Operator$string$1", "symbols": [{"literal":"<"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "Operator", "symbols": ["Operator$string$1"]},
    {"name": "Operator$string$2", "symbols": [{"literal":">"}, {"literal":"="}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "Operator", "symbols": ["Operator$string$2"]},
    {"name": "Operator", "symbols": [{"literal":"<"}]},
    {"name": "Operator", "symbols": [{"literal":">"}]},
    {"name": "Operator", "symbols": [{"literal":"="}]},
    {"name": "number$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "number$ebnf$1", "symbols": ["number$ebnf$1", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "number$ebnf$2$subexpression$1$ebnf$1", "symbols": [/[0-9]/]},
    {"name": "number$ebnf$2$subexpression$1$ebnf$1", "symbols": ["number$ebnf$2$subexpression$1$ebnf$1", /[0-9]/], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "number$ebnf$2$subexpression$1", "symbols": [{"literal":"."}, "number$ebnf$2$subexpression$1$ebnf$1"]},
    {"name": "number$ebnf$2", "symbols": ["number$ebnf$2$subexpression$1"], "postprocess": id},
    {"name": "number$ebnf$2", "symbols": [], "postprocess": function(d) {return null;}},
    {"name": "number", "symbols": ["number$ebnf$1", "number$ebnf$2"], "postprocess": d => parseFloat(d[0].join("") + (d[1] ? "." + d[1][1].join("") : ""))},
    {"name": "Condition", "symbols": [{"literal":"@"}, "Identifier", "_", "Operator", "_", "number"], "postprocess": 
        (d) => ({ type: "simple", ingredient: d[1], operator: d[3], value: parseFloat(d[5]) })
        },
    {"name": "Range", "symbols": ["number", "_", "Operator", "_", {"literal":"@"}, "Identifier", "_", "Operator", "_", "number"], "postprocess": 
        (d) => ({
            type: "range",
            lower: { value: parseFloat(d[0]), operator: d[2] },
            ingredient: d[5],
            upper: { operator: d[7], value: parseFloat(d[9]) }
        })
        },
    {"name": "Assignment", "symbols": [{"literal":"@"}, "Identifier", "_", {"literal":"="}, "_", "List"], "postprocess": 
        (d) => ({ type: "assignment", group: d[1], ingredients: d[5] })
        },
    {"name": "List$ebnf$1", "symbols": []},
    {"name": "List$ebnf$1$subexpression$1", "symbols": ["_", {"literal":","}, "_", {"literal":"@"}, "Identifier"]},
    {"name": "List$ebnf$1", "symbols": ["List$ebnf$1", "List$ebnf$1$subexpression$1"], "postprocess": function arrpush(d) {return d[0].concat([d[1]]);}},
    {"name": "List", "symbols": [{"literal":"@"}, "Identifier", "List$ebnf$1"], "postprocess": 
        (d) => [d[1]].concat(d[2].map(i => i[4]))
        },
    {"name": "IfExpression$string$1", "symbols": [{"literal":"I"}, {"literal":"F"}], "postprocess": function joiner(d) {return d.join('');}},
    {"name": "IfExpression$subexpression$1", "symbols": [{"literal":","}, "_", "Condition"]},
    {"name": "IfExpression", "symbols": ["IfExpression$string$1", {"literal":"("}, "Condition", {"literal":","}, "_", "Condition", "IfExpression$subexpression$1", {"literal":")"}], "postprocess": 
        (d) => ({
            type: "if",
            condition: d[2],
            then: d[5],
            else: d[7] ? d[7][2] : null
        })
        }
]
  , ParserStart: "Main"
}
if (typeof module !== 'undefined'&& typeof module.exports !== 'undefined') {
   module.exports = grammar;
} else {
   window.grammar = grammar;
}
})();
