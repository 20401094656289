import { useScenarioDetail } from '../../../_shared/context/scenario-detail-context';
import React, { useEffect, useRef, useState } from 'react';
import { useExploration, useSession } from '../../../_shared/context';
import { useDesign } from '../../../_shared/context/design-context';
import { useFormulations } from '../../../_shared/context/formulations-context';
import { ConstraintEditor } from './constraint-editor';
import { ConstraintList } from './constraint-list';
import { Card, Collapse, Divider } from 'antd';
import { CollapseProps } from 'antd/lib';
import { AimOutlined, LockOutlined } from '@ant-design/icons';
import './exploration-constraints-settings-v3.less'

export const ExplorationConstraintSettingV3 = () => {

  const items: CollapseProps['items'] = [
    {
      key: 'objectives',
      label: <div style={{ gap: 10, display: 'flex', alignItems: 'center' }}><span>Objectives</span><AimOutlined /></div>,
      children: (
        <div>
        </div>
      )
    },
    {
      key: 'constraints',
      label: <div style={{ gap: 10, display: 'flex', alignItems: 'center' }}><span>Constraints</span><LockOutlined /></div>,
      children: (
        <div>
          <ConstraintEditor />
          <Divider />
          <ConstraintList />
        </div>
      )
    }
  ];

  return <>
    <Collapse items={items} />
  </>;
};