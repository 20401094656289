import { DeleteOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { useScenarioDetail } from "../../../_shared/context/scenario-detail-context";
import React, { useEffect, useState } from "react";
import { Table, Tooltip, Typography } from "antd";
import '../exploration-constraint-setting-v3/exploration-constraints-settings-v3.less';
import { ConstraintInputType } from "__generated__/globalTypes";
import { deleteIngredientGroup, useGetIngredientsGroup } from "../../../network/services/ingredient-group.service";
import { useSession } from "../../../_shared/context";
import { ConstraintReason } from "@prisma/client";

const { Text } = Typography;

export const ConstraintList = () => {

  const {
    user,
    currentProject,
    ingredientsGroupList,
    setIngredientGroupList
  } = useSession();

  const deleteIngredientGroupMutation = deleteIngredientGroup();

  const {
    constraints,
    removeConstraint
  } = useScenarioDetail();

  const {
    data: ingredientsGroup,
    isSuccess: ingredientGroupIsSuccess,
    refetch
  } = useGetIngredientsGroup({
    projectId: currentProject?.id,
    organizationId: user?.organizationId,
  });

  const [constraintsData, setConstraintsData] = useState<
    { key: string; constraintName: React.JSX.Element | undefined; user: React.JSX.Element | undefined; reduction: React.JSX.Element | number; delete: React.JSX.Element }[]
  >([]);

  const columns = [
    {
      dataIndex: 'constraintName',
      key: 'constraintName',
      render: (values: { name: string, reason: string }) => (<>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', gap: 6 }}>
            <Text>{values.name}</Text>
            {values.ingredients &&
              <Tooltip title={values.ingredients}>
                <InfoCircleOutlined />
              </Tooltip>
            }
          </div>
          <Text type="secondary" style={{ fontSize: 12 }}>
            {values.reason ? values.reason[0].toUpperCase() + values.reason.substring(1).toLowerCase() : undefined}
          </Text>
        </div>
      </>

      ),
    },
    {
      dataIndex: 'delete',
      key: 'delete',
      render: (values: { c: ConstraintInputType, ig: string }) => (
        <div style={{ textAlign: 'center', color: '#FF4D4F', cursor: 'pointer' }}>
          <DeleteOutlined onClick={() => values.ig ? handleDelete(ingredientsGroup[values.ig][0].groupId) : removeConstraint(values.c)} />
        </div>
      ),
    },
  ]

  const expandColumns = [

    {
      title: <div style={{ textAlign: 'center' }}>User</div>,
      dataIndex: 'user',
      key: 'user',
      render: (text: string) => (
        <div style={{ textAlign: 'center' }}>
          {text}
        </div>
      ),
    },
    {
      title: <div style={{ textAlign: 'center' }}>Reduction</div>,
      dataIndex: 'reduction',
      key: 'reduction',
      render: (reduction: number) => (
        <div
          style={{
            textAlign: 'center',
            backgroundColor: '#FDEDEE',
            borderRadius: '4px',
            padding: '2px 8px',
            color: '#FF4D4F',
          }}
        >
          {reduction}%
        </div>
      ),
    },
    {
      title: <div style={{ display: 'flex', justifyContent: 'center' }}>Description</div>,
      dataIndex: 'description',
      key: 'description',
      render: (values: string) => (
        <Text
          style={{ maxWidth: '150px', display: 'block' }}
          ellipsis={{ tooltip: values }}
        >
          {values}
        </Text>
      ),
    },

  ];

  useEffect(() => {
    let list = constraints.map((c) => ({
      key: c.name!,
      constraintName: { name: c.name ?? undefined, reason: c.reason },
      user: c.createdBy ? `${c.createdBy.firstName} ${c.createdBy.lastName}` : 'Current User',
      reduction: 8,
      delete: { c: c },
      description: c.comment
    }));
    if (ingredientGroupIsSuccess)
      Object.keys(ingredientsGroup).forEach((ig) => {
        list.push({
          key: `@${ingredientsGroup[ig][0].group.name}`,
          constraintName: {
            name: `${ingredientsGroup[ig][0].group.name}`,
            reason: 'Ingredient group',
            ingredients: ingredientsGroup[ig].map((ingredient) => ingredient.ingredient.name).join(', '),
          },
          user: ingredientsGroup[ig][0].group.createdBy ? `${ingredientsGroup[ig][0].group.createdBy.firstName} ${ingredientsGroup[ig][0].group.createdBy.lastName}` : 'Current User',
          delete: { ig: ingredientsGroup[ig][0].groupId }
        })
      })

    setConstraintsData(list);
  }, [constraints, ingredientsGroup])

  const handleDelete = (gorup_id: string) => {
    deleteIngredientGroupMutation.mutate(
      {
        projectId: currentProject?.id ?? '',
        organizationId: user?.organizationId ?? '',
        group_id: gorup_id,
      },
      {
        onSuccess: response => {
          setIngredientGroupList(
            ingredientsGroupList.filter(e => e.id !== gorup_id)
          );
          refetch();
        },
      }
    );
  };

  return <>
    <div className="custom-constraints-table">
      {constraintsData &&
        <Table
          dataSource={constraintsData}
          columns={columns}
          pagination={false}
          showHeader={false}
          style={{
            border: 'none', boxShadow: 'none', paddingLeft: 0, marginLeft: 0
          }}
          rowClassName={() => 'custom-parent-row'}
          expandable={{
            expandedRowRender: (record) => <div style={{ paddingLeft: 0, margin: 0 }}>
              <Table
                dataSource={constraintsData.filter((c) => c.key === record.key)}
                columns={expandColumns}
                pagination={false}
                className="expanded-table"
                rowClassName={() => 'custom-expanded-row'}
              />
            </div>,
            rowExpandable: (record) => Object.values(ConstraintReason).includes(record.constraintName.reason),
          }}
        />
      }
    </div>
  </>;
}