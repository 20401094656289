import {
  DesignProductRecordType,
  DesignResultType,
  PolledDesignsType,
} from './types';
import { stringify } from 'csv-stringify/sync';
import {
  ConstraintInputType,
  ConstraintType,
  IngredientList,
  Objective,
  Constraint,
  ObjectiveType,
  VariableType,
  FormulationItemType,
  Outcome,
} from '../../../../../__generated__/globalTypes';
import isEqual from 'lodash/isEqual';
import { BaseProject, IngredientType } from '../../../_shared/hooks';
import {
  calculateCompositionTotals,
  calculateFormulationCost,
} from '../../../_shared/utils/util';
import { MutableRefObject } from 'react';
import { FormulationType } from '../../../_shared/context/formulations-context';
import { DesignJobResponse } from '../shared/goals/types';

export const emptyConstraint: ConstraintInputType = {
  id: '',
  constraintType: ConstraintType.EQUALITY,
  lowerBounds: null,
  upperBounds: null,
  coefficients: [],
  values: [],
  variables: [],
};

export const changeColumnBackground = ({
  name,
  color = 'red',
  scrollToColumn = true,
  addShadow = true,
  columnRefMap,
  pointRefMap,
}: {
  name: string;
  color: string;
  scrollToColumn: boolean;
  addShadow: boolean;
  columnRefMap?: MutableRefObject<null | Map<string, HTMLElement>>;
  pointRefMap?: MutableRefObject<null | Map<string, SVGSVGElement>>;
}) => {
  const normalizedClassName = normalizeClassName(name);
  const node = columnRefMap?.current?.get(normalizedClassName);
  const point = pointRefMap?.current?.get(normalizedClassName);
  if (addShadow) {
    point?.style.setProperty(
      '-webkit-filter',
      'drop-shadow(0px 0px 5px black)'
    );
  } else {
    point?.style.setProperty('-webkit-filter', 'unset');
  }
  const collection = document.querySelectorAll<HTMLElement>(
    `.${normalizedClassName}`
  );
  for (let index = 0; index < collection.length; index++) {
    const item = collection[index];
    item.style.backgroundColor = color;
  }

  if (scrollToColumn) {
    setTimeout(function () {
      node?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'center',
      });
    }, 0);
  }
};

export const normalizeClassName = (str: string): string =>
  btoa(str).replace(/=/g, '');

export const processDesignResults = (
  latestDesign: PolledDesignsType,
  currentProject: BaseProject | undefined
): DesignResultType[] | undefined => {
  const ingredientList = currentProject?.ingredientList as
    | IngredientList[]
    | undefined;
  if (!latestDesign?.projectJob?.debugOutput) {
    return undefined;
  }

  const parsedOutput: DesignJobResponse | undefined = JSON.parse(
    latestDesign?.projectJob.debugOutput
  );

  if (!parsedOutput?.results || !ingredientList) return undefined;

  return combineProductAndDatasetResults(
    parsedOutput,
    currentProject,
    latestDesign?.product?.productVersion
  );
};

export const findConstraint = (
  constraint: ConstraintInputType,
  constraints: ConstraintInputType[]
) =>
  constraints.findIndex(c => {
    if (isEqual(constraint, c)) {
      return true;
    }
  });

/**
 * The dataset formulations are not saved in the product table
 * @param parsedOutput
 * @param products
 */
const combineProductAndDatasetResults = (
  parsedOutput: DesignJobResponse,
  currentProject: BaseProject | undefined,
  products?: NonNullable<DesignProductRecordType>['productVersion']
) => {
  const ingredientList = currentProject?.ingredientList as
    | IngredientList[]
    | undefined;
  const ingredientMap = new Map<string, IngredientType | undefined>();
  const results: DesignResultType[] = [];
  const ingredientCompositions = currentProject?.ingredientComposition ?? [];
  //centralize the Turing Score
  const existingFormulations =
    parsedOutput?.results.observations?.map(f => {
      const { scores, ...everythingElse } = f;

      return {
        ...everythingElse,
        turingScore: scores.total_desirability - scores.penalty,
        scores: {
          ...scores,
        },
      };
    }) ?? [];

  const [firstExistingFormulation] = existingFormulations;

  firstExistingFormulation?.formulation.formulation.forEach(f => {
    ingredientMap.set(
      f.name,
      ingredientList?.find(ingredient => ingredient.ingredient.name === f.name)
    );
  });

  //The highest scoring formulation is the "benchmark"
  existingFormulations
    .sort((b1, b2) => b2.turingScore - b1.turingScore)
    .forEach((f, i) => {
      let isBenchmark = false;

      /**
       * TODO: Use given name from ml api if exists
       */
      let setName = f.formulation.name ?? `Existing Formulation ${i + 1}`;
      if (i === 0) {
        isBenchmark = true;
        setName = f.formulation.name
          ? f.formulation.name
          : `Benchmark Formulation`;
      }
      const formattedFormulation = f.formulation.formulation.reduce(
        (acc: any, q) => {
          let ing = ingredientMap.get(q.name);
          if (ing) {
            acc[ing?.ingredient.id] = q.value;
            return acc;
          }
        },
        {}
      );

      f.formulation.totalCostScore = Number(
        calculateFormulationCost(formattedFormulation, ingredientList ?? [])
      );
      if (!ingredientList) {
        throw new Error(`Missing ingredientList`);
      }
      const formattedQuantites = Object.assign(
        {},
        ...f.formulation.formulation.map(item => {
          const ing = ingredientMap.get(item.name);
          const ingId = ing?.ingredient?.id ?? '';
          return {
            [ingId]: item.value,
          };
        })
      );
      f.formulation.ingredientCompositionTotals = calculateCompositionTotals(
        formattedQuantites,
        ingredientList,
        ingredientCompositions
      );

      const outcomes = f.scores.desirability_components.map(d => ({
        outcomeName: d.objective.target_variable,
        value: `${d.value}`,
        desirability: d.desirability,
        /**TODO hardcoding the applicability and reliablity is bad
         * But as of right now we don't use it
         */
        applicability: 999,
        reliability: 999,
      }));

      results.push({
        ...f,
        isBenchmark,
        productName: setName,
        isGenerated: false,
        outcomes,
      });
    });

  //TODO: We don't need all of these scores, the object can be much smaller
  // But this is the easiest way to incorporate the product info at the moment

  products?.forEach((pv, i) => {
    const {
      name,
      formulation,
      designScore,
      cost,
      simulationProductVersions: spv,
    } = pv;
    if (!designScore) {
      throw new Error(
        `Adatpive Learning Scores not found for product ${pv.name}`
      );
    }
    if (!ingredientList) {
      throw new Error(`Missing ingredientList`);
    }

    const ingredientCompositionTotals = calculateCompositionTotals(
      pv?.formulation?.quantities,
      ingredientList,
      ingredientCompositions
    );

    const formattedFormulation = [];
    for (const [key, value] of Object.entries(formulation.quantities)) {
      const ing = ingredientList?.find(i => `${i.ingredient.id}` === `${key}`);
      if (ing === undefined) {
        throw new Error(`Undefined ingredient ${key}`);
      }

      formattedFormulation.push({
        name: ing.ingredient.name,
        value: value as string | number,
      });
    }

    const formattedOutcomes = spv ? spv[0]?.outcomes : undefined;

    results.push({
      outcomes: formattedOutcomes,
      formulation: {
        name: pv.name,
        totalCostScore: Number(cost),
        ingredientCompositionTotals,
        //This has to be an array of name to value
        formulation: formattedFormulation,
      },
      turingScore: designScore.totalDesirability - designScore.penalty,
      scores: {
        desirability_components: [],
        penalty: designScore.penalty,
        score: designScore.score,
        total_desirability: designScore.totalDesirability,
      },
      isBenchmark: false,
      productName: name,
      //We no longer have the table
      //If we bring it back I'll worry about this
      isGenerated: true,
    });
  });
  return results;
};

export const getDesignTemplateDownload = (
  latestDesign: PolledDesignsType | undefined,
  currentProject: BaseProject
) => {
  if (!latestDesign) return 'No Records';
  const outcomes = currentProject?.activeModel?.outcomes;
  if (!outcomes) return '';
  const ingredientList = currentProject?.ingredientList;

  const ingredientColumnIndex = 0;

  // 'y' Indexes of predefined rows before adding the headers
  const internalIdIndex = 0;
  const turingIdIndex = 1;

  const productVersions = latestDesign?.product?.productVersion;
  if (!productVersions) return '';

  const productVersionNames = productVersions.map(version => version.name);
  // Our DB saves the ingredient ID, not the name, in the formulation quantities so we need a efficient way to retrieve these values
  const ingredientLookup = new Map<string, string>();
  ingredientList?.forEach(i => {
    ingredientLookup.set(String(i.ingredient.id), i.ingredient.name);
  });

  const csvHeaders: string[] = ['name', ...productVersionNames];
  const csvBody: string[][] = [];
  csvBody[internalIdIndex] = ['Internal Id'];
  csvBody[turingIdIndex] = ['Turing ID'];

  // Fill in the name column with each ingredient
  ingredientLookup.forEach(value => csvBody.push([value]));

  // Add outcomes to the bottom of the CSV
  outcomes.forEach(outcome => {
    // Each outcome should have the remaining rows filled to keep csv square
    const outcomeBounds =
      outcome.type === VariableType.NUMERIC
        ? ` (${outcome?.lower} - ${outcome?.upper})`
        : '';
    const outcomeRow: string[] = Array(csvHeaders.length).fill('');
    outcomeRow[0] = `${outcome.targetVariable}${outcomeBounds}`;
    csvBody.push(outcomeRow);
  });

  for (const version of productVersions) {
    // The 'x' coordinate aka version column
    const headerColumnIndex = csvHeaders.indexOf(version.name);

    // Leave this field empty for them to fill in
    csvBody[internalIdIndex][headerColumnIndex] = '';
    csvBody[turingIdIndex][headerColumnIndex] = version.formulation.id;

    for (const quantity of Object.entries(version.formulation.quantities)) {
      const [id, value] = quantity;
      const ingredientName = ingredientLookup.get(id);

      // The 'y' coordinate
      const ingredientRowIndex = csvBody.findIndex(
        row => row[ingredientColumnIndex] === ingredientName
      );
      csvBody[ingredientRowIndex][headerColumnIndex] = String(value);
    }
  }
  //Add the headers to the csv
  csvBody.unshift(csvHeaders);
  return stringify(csvBody);
};

const retrieveObjectiveCSVText = (obj: Objective) => {
  let textValue = '';
  switch (obj.objectiveType) {
    case ObjectiveType.MAXIMIZE:
      textValue = '';
      break;
    case ObjectiveType.MINIMIZE:
      textValue = '';
      break;
    case ObjectiveType.IN_RANGE:
      textValue = `${obj.lower}-${obj.upper}`;
      break;
    case ObjectiveType.TARGET_VALUE:
      textValue = `${obj.value}`;
      break;
    default:
      textValue = '';
      break;
  }
  return textValue;
};
const retrieveConstraintCSVText = (
  constraint: Omit<Constraint, 'id'>,
  currentProject: BaseProject
) => {
  let textValue = '';
  switch (constraint.constraintType) {
    case ConstraintType.AMOUNT:
      const ingredientComposition = currentProject?.ingredientComposition?.find(
        p => p.id === constraint.ingredientCompositionId
      );
      textValue = `${ingredientComposition?.name} ${constraint.lowerBounds}-${constraint.upperBounds}`;
      break;
    case ConstraintType.EQUALITY:
      textValue = constraint?.values?.[0]?.value ?? '';
      break;
    case ConstraintType.RANGE:
      textValue = `${constraint?.coefficients?.[0]?.name ?? '' + ':'} ${
        constraint.lowerBounds
      }-${constraint.upperBounds}`;
      break;
    case ConstraintType.COUNT:
      textValue = `${constraint.lowerBounds}-${
        constraint.upperBounds
      } ${constraint?.variables?.join(', ')} `;
      break;
    default:
      textValue = '';
      break;
  }
  return textValue;
};

const OBJECTIVE_TYPE_LABELS = {
  [ObjectiveType.IN_RANGE]: 'Range',
  [ObjectiveType.TARGET_VALUE]: 'Target',
  [ObjectiveType.MAXIMIZE]: 'Maximize',
  [ObjectiveType.MINIMIZE]: 'Minimize',
};

const CONSTRAINT_TYPE_LABELS = {
  [ConstraintType.AMOUNT]: 'Composition',
  [ConstraintType.EQUALITY]: 'Target',
  [ConstraintType.COUNT]: 'Category',
  [ConstraintType.RANGE]: 'Range',
};

export const getFormulationsExport = (
  formulations: FormulationType[],
  options?: {
    removeOutcomeValues: boolean;
    includeOutcomeBounds: boolean;
    outcomes?: Outcome[];
  }
) => {
  const csv: [string | number][] = [];
  // Create the initial structure of the CSV with the top row being formulations
  csv[0] = ['Name'];
  csv[1] = ['Internal ID'];
  csv[2] = ['Turing ID'];
  csv[3] = ['Cost'];
  for (const formulation of formulations) {
    if (formulation?.key) {
      csv[0].push(formulation?.key);
    }
    csv[1].push('');
    csv[2].push(formulation.id);
    csv[3].push(formulation.totalCostScore ?? 0);
    const sortedItems = formulation.items.sort((a, b) => {
      if (
        a.type === FormulationItemType.TARGET_PREDICTED ||
        a.type === FormulationItemType.TARGET_MEASURED
      ) {
        return 1;
      } else {
        return -1;
      }
    });
    for (let [index, item] of sortedItems.entries()) {
      // Fill in the Name column with all the item names
      // We add  + 1 to skip the header row
      const outcome = options?.outcomes?.find(
        outcome => outcome.targetVariable === item.variable.name
      );
      /**
       * Important! importFormulationsFromTestPlanCsv in the project service relies on this
       * outcome bounds pattern to match. If any changes are made to this, the same needs to be updated
       * in the project service
       */
      let rowName =
        options?.includeOutcomeBounds && outcome
          ? `${item.variable.name} (${outcome?.lower} - ${outcome?.upper})`
          : item.variable.name;
      csv[index + 4] = [rowName];
    }
  }
  // Fill in the body
  for (const formulation of formulations) {
    //const cName = formulation?.name ?? formulation?.key;
    const cName = formulation?.key;
    const columnIndex = csv[0].findIndex(columnName => cName === columnName);
    for (let item of formulation.items) {
      const leaveValueBlank =
        (item.type === FormulationItemType.TARGET_PREDICTED ||
          item.type === FormulationItemType.TARGET_MEASURED) &&
        options?.removeOutcomeValues;
      const itemName = item.variable.name;
      const rowIndex = csv.findIndex(row => String(row[0]) === itemName);

      if (rowIndex > -1) {
        csv[rowIndex][columnIndex] = leaveValueBlank ? '' : item.value;
      }
    }
  }
  return stringify(csv);
};

/**
 * Returns the CSV download of the results
 */
export const getDesignDownload = ({
  designResults,
  ingredients,
  hasPricing,
  latestDesign,
  currentProject,
}: {
  designResults: DesignResultType[];
  ingredients?: IngredientType[];
  hasPricing: boolean;
  latestDesign?: PolledDesignsType;
  currentProject: BaseProject;
}) => {
  /**
   * Use the generated formulation as the source of truth for outcomes and ingredients to display.
   * If no results found default to a generated formulation
   */
  const sourceOfTruth = designResults.some(f => f.isGenerated)
    ? designResults.find(f => f.isGenerated)
    : designResults[0];
  if (!designResults.length || !sourceOfTruth) return 'No Records';

  const ingredientMap = new Map<string, IngredientType | undefined>();
  const formulationdata: string[][] = [];

  /**
   * @ingredientColumn - actually represents the lable for the row, could be a score, outcome, or ingredient
   */
  const ingredientColumn: string[] = [];
  let formulationScoresIdx: number = 0;

  //Create the ingredient source of truth
  sourceOfTruth.formulation.formulation.forEach(f => {
    ingredientColumn.push(f.name);
    ingredientMap.set(
      f.name,
      ingredients?.find(ingredient => ingredient.ingredient.name === f.name)
    );
  });

  // Add outcome names
  sourceOfTruth.outcomes?.forEach(outcome => {
    ingredientColumn.push(outcome.outcomeName);
  });

  // Hard code Name header
  formulationdata[0] = ['Name'];

  // Fill in the ingredients column
  ingredientColumn.forEach(
    (ingredient, i) => (formulationdata[i + 1] = [ingredient])
  );

  formulationdata[0][1] = 'Price';
  for (let row of formulationdata) {
    let ingredient = ingredientMap.get(row[0]);
    if (ingredient) {
      row[1] = String(ingredient?.price ?? 0);
    }
  }

  designResults.forEach(result => {
    //Set the starting starting row for the formulation ingredients
    formulationScoresIdx = ingredientColumn.length + 1;
    // Add Product Names to header row
    formulationdata[0].push(result.productName);
  });

  designResults.forEach(result => {
    const { formulation, outcomes } = result;

    formulation.formulation.forEach(f => {
      const ingredientRowIndex = ingredientColumn.indexOf(f.name) + 1;
      formulationdata[ingredientRowIndex].push(String(f.value));
    });

    const columnIndex = formulationdata[0].findIndex(
      name => name === result.productName
    );

    outcomes?.forEach(outcome => {
      const rowIndex = ingredientColumn.indexOf(outcome.outcomeName);
      if (rowIndex !== -1) {
        // If we can't find the row don't set the value
        formulationdata[rowIndex + 1][columnIndex] = outcome.value;
      }
    });
    formulation.totalCostScore = formulation.totalCostScore;
    addFormulationScores(
      formulationdata,
      formulationScoresIdx,
      result,
      hasPricing
    );
  });

  if (latestDesign?.objectives) {
    formulationdata.push([], ['Objectives']);

    for (const obj of latestDesign?.objectives ?? []) {
      formulationdata.push([
        obj?.targetVariable,
        OBJECTIVE_TYPE_LABELS[obj?.objectiveType],
        retrieveObjectiveCSVText(obj),
      ]);
    }
  }
  if (latestDesign?.objectives) {
    formulationdata.push([], ['Constraints']);

    for (const constraint of latestDesign?.constraints ?? []) {
      formulationdata.push([
        constraint?.name ??
          `${CONSTRAINT_TYPE_LABELS[constraint?.constraintType]} Constraint`,
        CONSTRAINT_TYPE_LABELS[constraint?.constraintType],
        retrieveConstraintCSVText(constraint, currentProject),
      ]);
    }
  }
  return stringify(formulationdata, {});
};

const addFormulationScores = (
  formulationdata: string[][],
  resultScoresIdx: number,
  result: DesignResultType,
  hasPricing: boolean
) => {
  const formulationScores = [
    {
      name: 'Total Desirability',
      value: `${result.scores.total_desirability}`,
    },
    { name: 'Penalty', value: `${result.scores.penalty}` },
    // can we just use result.scores.score here?
    {
      name: 'Turing Score',
      value: `${result?.turingScore}`,
    },
    ...(hasPricing
      ? [
          {
            name: 'Cost Score',
            value: String(result.formulation.totalCostScore),
          },
        ]
      : []),
  ];

  formulationScores.forEach((fs, i) => {
    const { name, value } = fs;
    const newIdx = resultScoresIdx + i;
    const emptyPriceCell = '';
    const scoreRow = formulationdata[newIdx] ?? [name, emptyPriceCell];
    scoreRow.push(value);
    formulationdata[newIdx] = scoreRow;
  });
};

export const emptyIngredient: IngredientType = {
  category: {
    color: '',
    id: '',
    name: '',
  },
  ingredient: {
    id: 0,
    name: '',
  },
  isActive: true,
  isRequired: true,
  isTestCondition: true,
  values: [],
  ingredientCompositions: [],
  lowerLimit: 0,
  upperLimit: 0,
};
