import { useMutation, useQuery } from "react-query";
import { RawDataRepository } from "../repositories/rawdata.repository";
import { OrgIdProjId, OrgIdProjIdOutputId, PutRawDataOut } from "../interfaces/rawdata.interface";

export const useFormulationItemToRawData = () => {
  const mutation = useMutation((data: OrgIdProjId) => {
    return RawDataRepository.postFormulationItemToRawData(data);
  });
  return mutation;
};

export const useGetRawData = (data: {
  organizationId: string;
  projectId: string;
}): {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  data: any;
  refetch: () => void;
} => {
  return useQuery(
    [`getRawData`, data.organizationId, data.projectId],
    RawDataRepository.getRawData,
    {
      enabled: true,
      cacheTime: 0,
      select: response => {
        return response.data;
      },
    }
  );
};

export const usePutRawData = () => {
  const mutation = useMutation((data: PutRawDataOut) => {
    return RawDataRepository.putRawData(data);
  });
  return mutation;
};

export const usefinishRawData = () => {
  const mutation = useMutation((data: OrgIdProjId) => {
    return RawDataRepository.finishRawData(data);
  });
  return mutation;
};

export const useDeleteRaw = () => {
  const mutation = useMutation((data: OrgIdProjIdOutputId) => {
    return RawDataRepository.deleteRawData(data);
  });
  return mutation;
};

export const useRemoveRaw = () => {
  const mutation = useMutation((data: OrgIdProjId & {
    names: string[]
  }) => {
    return RawDataRepository.removeOutputRaw(data)
  })
  return mutation
}

