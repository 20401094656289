import { Flex } from 'antd';
import React, { useState } from 'react';

import { css } from '@emotion/react';
import { useIngredients } from '../../../_shared/hooks';
import { isNaN } from 'lodash';
import './experiment-constraints-min-cost.less'
import { useTranslation } from 'react-i18next';
export const ExperimentConstraintsMinCost = ({
  minCost
}: {
  minCost: any[]
}) => {
  const { ingredientByName } = useIngredients();
  const { t } = useTranslation();
  return (
    <div>
      {(
        <div className='task-chat' id="constraint-min-cost">
          <div className='title'>
            {t('discover-solutions.page.exploration-page.page.experimentConstraintMinCost.title')} {minCost[0] / 100}
          </div>
          <div className='formulation'>
            <Flex justify="space-between">
              <Flex gap={5} align="center" style={{ padding: '10px 0' }}>
                <div
                  className='title'
                >
                  {t('discover-solutions.page.exploration-page.page.experimentConstraintMinCost.page.ingredientLabel')}
                </div>
              </Flex>
            </Flex>

            <div className='formulation-generated'>
              {minCost[1]?.formulation?.map((item: { name: string, value: string }) => {
                const ingredient = ingredientByName.get(item.name);
                const ingredientValue = !isNaN(Number(item.value)) && !ingredient?.unit
                  ? item.value + '%'
                  : item.value + ingredient?.unit;
                return (
                  <div
                    className="row">
                    <div className="ingredient name">
                      {`${item.name} : `}
                    </div>
                    <div className='value'>
                      {ingredientValue}
                    </div>
                  </div>
                );
              })}
            </div>

          </div>
        </div>
      )}
    </div>
  );
};
